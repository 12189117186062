<template>
    <div class="col-xs-12 flex" style='position: relative;'>
        <div class="col-xs-4 h100">
            <div class="dbg" name="selector">
                <h3>Выбор</h3>
                <v-select :items="instances ?? []" :disabled="!instances" :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                   }" v-model="selection.instance" label="Подземелье">
                    <template v-slot:item="{item}">
                        <v-list-item @click.native="selection.instance = item.raw; selection.boss = null; refresh_bosses()">{{item.raw.name}}</v-list-item>
                    </template>
                    <template v-slot:selection="{item}">
                        {{item.raw.name}}
                    </template>
                </v-select>
                <v-select :items="bosses ?? []" :disabled="!bosses" :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                   }" v-model="selection.boss" label="Босс">
                    <template v-slot:item="{item}">
                        <v-list-item @click="selection.boss = item.raw">{{item.raw.name}}</v-list-item>
                    </template>
                    <template v-slot:selection="{item}">
                        {{item.raw.name}}
                    </template>
                </v-select>
                <input v-model="ignore" type="checkbox" name="fullrandom" id="fullrandom" checked value="1"><label name="fullrandom" for="fullrandom"> Исключить одинаковый лут?</label>
                <v-btn class="span-btn btn btn-warcraft" :disabled="!selection.boss" @click="generated_data = null; generate()" name="generate">Генерировать</v-btn>
            </div>
            <div id="s-wow-tooltip" class='active' ref="tooltip" v-show="tooltip_show">
                <template v-if="tooltip">
                    <div style="font-size: 14px;" :class="`q${tooltip.quality}`">
                        {{  tooltip.name }}
                    </div>
                    <div v-if="tooltip.is_heroic" style="color: #1eff00;">Героический</div>
                    <div v-if="tooltip.bonding">
                        <span v-if="tooltip.bonding == 1">Становится персональным при получении</span>
                        <span v-if="tooltip.bonding == 2">Становится персональным при надевании</span>
                    </div>
                    <table style="width: 100%;">
                        <tr>
                            <td style="text-align:left;">{{ subClasses[tooltip.class][tooltip.subclass] }}</td>
                            <td style="text-align:right;">{{ subClasses[tooltip.class][tooltip.subclass] == inventoryType[tooltip.inventory_type] ? '': inventoryType[tooltip.inventory_type]}}</td>
                        </tr>
                    </table>
                    <table v-if="tooltip.dmg_min1 || tooltip.dmg_max1" style="width: 100%;">
                        <tr>
                            <td style="text-align:left;">Урон: {{ tooltip.dmg_min1 }} - {{ tooltip.dmg_max1 }}</td>
                            <td style="text-align:right;">Скорость: {{ (tooltip.delay * 0.001).toFixed(2) }}</td>
                        </tr>
                    </table>

                    <div v-if="tooltip.dps">({{ tooltip.dps.toFixed(2) }} урона в секунду)</div>
                    <div v-if="tooltip.armor">Броня: {{ tooltip.armor }}</div>
                    <div v-if="tooltip.stat_type1 && tooltip.stat_type1 < 12">{{ tooltip.stat_value1 > 0 ? `+${tooltip.stat_value1}`: `-${tooltip.stat_value1}` }} {{ statType[tooltip.stat_type1] }}</div>
                    <div v-if="tooltip.stat_type2 && tooltip.stat_type2 < 12">{{ tooltip.stat_value2 > 0 ? `+${tooltip.stat_value2}`: `-${tooltip.stat_value2}` }} {{ statType[tooltip.stat_type2] }}</div>
                    <div v-if="tooltip.stat_type3 && tooltip.stat_type3 < 12">{{ tooltip.stat_value3 > 0 ? `+${tooltip.stat_value3}`: `-${tooltip.stat_value3}` }} {{ statType[tooltip.stat_type3] }}</div>
                    <div v-if="tooltip.stat_type4 && tooltip.stat_type4 < 12">{{ tooltip.stat_value4 > 0 ? `+${tooltip.stat_value4}`: `-${tooltip.stat_value4}` }} {{ statType[tooltip.stat_type4] }}</div>
                    <div v-if="tooltip.stat_type5 && tooltip.stat_type5 < 12">{{ tooltip.stat_value5 > 0 ? `+${tooltip.stat_value5}`: `-${tooltip.stat_value5}` }} {{ statType[tooltip.stat_type5] }}</div>
                    <div v-if="tooltip.stat_type6 && tooltip.stat_type6 < 12">{{ tooltip.stat_value6 > 0 ? `+${tooltip.stat_value6}`: `-${tooltip.stat_value6}` }} {{ statType[tooltip.stat_type6] }}</div>
                    <div v-if="tooltip.stat_type7 && tooltip.stat_type7 < 12">{{ tooltip.stat_value7 > 0 ? `+${tooltip.stat_value7}`: `-${tooltip.stat_value7}` }} {{ statType[tooltip.stat_type7] }}</div>
                    <div v-if="tooltip.stat_type8 && tooltip.stat_type8 < 12">{{ tooltip.stat_value8 > 0 ? `+${tooltip.stat_value8}`: `-${tooltip.stat_value8}` }} {{ statType[tooltip.stat_type8] }}</div>
                    <div v-if="tooltip.stat_type9 && tooltip.stat_type9 < 12">{{ tooltip.stat_value9 > 0 ? `+${tooltip.stat_value9}`: `-${tooltip.stat_value9}` }} {{ statType[tooltip.stat_type9] }}</div>
                    <div v-if="tooltip.stat_type10 && tooltip.stat_type10 < 12">{{ tooltip.stat_value10 > 0 ? `+${tooltip.stat_value10}`: `-${tooltip.stat_value10}` }} {{ statType[tooltip.stat_type10] }}</div>
                    <div v-if="tooltip.sockets.length">
                        <!-- 
                            .socket-meta
                            .socket-red
                            .socket-yellow
                            .socket-blue
                            .socket-prismatic
                            .socket-hydraulic
                            .socket-cogwheel
                         -->
                        <div v-for="socket of tooltip.sockets">
                            <span :class="{
                                'socket-meta': socket.type == 'meta',
                                'socket-red': socket.type == 'red',
                                'socket-blue': socket.type == 'blue',
                                'socket-yellow': socket.type == 'yellow',
                                'socket-prismatic': socket.type == 'prismatic',
                                'socket-hydraulic': socket.type == 'hydraulic',
                                'socket-cogwheel': socket.type == 'cogwheel',
                            }" style="padding-left: 18px; color: grey;">{{ (()=>{
                                if(socket.type == 'meta') return 'Особое гнездо';
                                if(socket.type == 'red') return 'Красное гнездо';
                                if(socket.type == 'blue') return 'Синее гнездо';
                                if(socket.type == 'yellow') return 'Желтое гнездо';
                                if(socket.type == 'prismatic') return 'Радужное гнездо';
                            })() }}</span>
                            
                        </div>
                    </div>
                    <div v-if="tooltip.socket_bonus_ench" style="color: grey;">При соответствии цвета: {{ tooltip.socket_bonus_ench.name }}</div>
                    <div v-if="tooltip.durability">Прочность {{ tooltip.durability }}/{{ tooltip.durability }}</div>
                    <div v-if="tooltip.required_level">Требуемый уровень: {{ tooltip.required_level }}</div>
                    <div v-if="tooltip.item_level" :style="{'color': 'gold'}">Уровень предмета: {{ tooltip.item_level }}</div>
                    <div v-if="tooltip.required_spell">
                        Требуется: {{ tooltip.required_spell.name }}
                    </div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type1 && tooltip.stat_type1 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type1], [tooltip.stat_value1]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type2 && tooltip.stat_type2 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type2], [tooltip.stat_value2]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type3 && tooltip.stat_type3 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type3], [tooltip.stat_value3]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type4 && tooltip.stat_type4 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type4], [tooltip.stat_value4]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type5 && tooltip.stat_type5 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type5], [tooltip.stat_value5]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type6 && tooltip.stat_type6 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type6], [tooltip.stat_value6]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type7 && tooltip.stat_type7 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type7], [tooltip.stat_value7]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type8 && tooltip.stat_type8 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type8], [tooltip.stat_value8]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type9 && tooltip.stat_type9 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type9], [tooltip.stat_value9]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.stat_type10 && tooltip.stat_type10 >= 12">Если на персонаже: {{ formatString(statType[tooltip.stat_type10], [tooltip.stat_value10]) }}</div>
                    <div style="color: #1eff00;" v-if="tooltip.spell_triggers.length" v-for="spell of tooltip.spell_triggers">
                        <span v-if="spell.trigger == 1">Если на персонаже: {{ spell.description }}</span>
                        <span v-if="spell.trigger == 0">Использование: {{ spell.description }}</span>
                    </div>
                    <div style="color: gold;" v-if="tooltip.description 
                    && tooltip.description.length 
                    && (
                        !tooltip.description.includes('На этом оружии не отображаются иллюзии и другие визуальные эффекты.')
                        ||
                        !tooltip.description.includes('Бонус отключен на аренах и полях боя.')
                    )">{{ tooltip.description }}</div>
                    <div v-if="tooltip.price && (tooltip.price.gold > 0 || tooltip.price.silver > 0 || tooltip.price.copper > 0)">
                        Цена: 
                        <span v-if="tooltip.price.gold" class="moneygold">{{ tooltip.price.gold }}</span>
                        <span v-if="tooltip.price.silver" class="moneysilver">{{ tooltip.price.silver }}</span>
                        <span v-if="tooltip.price.copper" class="moneycopper">{{ tooltip.price.copper }}</span> 
                    </div>
                    
                </template>
            </div>
        </div>
    
        <div class="col-xs-4" name="items">
            <div name="loot" id="0" ref="itemloot">
                <div class="loot-top">
                    <div class="loot-title">Предметы</div>
                </div>
                <div class="loot-bottom">
                    <div class="loot-bg">
                        <template v-if="generated_data">
                            <div class="loot-item" id="$lootid" v-for="item of generated_data" @mouseover="itemhover($event, item.json_data)" @mouseout="itemoff">
                                <div class="loot-item-icon">
                                    <img :src="item.json_data.icon">
                                </div>
                                <div class="loot-item-name">
                                    <div style="width: 215px; height: 75px;">
                                        <div class="name">
                                            <div class="name-in" :class="`q${item.json_data.quality}`">{{ item.json_data.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="loot-item" id="$lootid"><div class="loot-item-icon"><img :src="require('@/assets/empty_slot.png')"></div><div class="loot-item-name"><div style="width: 215px; height: 75px;"><div class="name"><div class="name-in"></div></div></div></div></div>
                            <div class="loot-item" id="$lootid"><div class="loot-item-icon"><img :src="require('@/assets/empty_slot.png')"></div><div class="loot-item-name"><div style="display: table; width: 215px; height: 75px;"><div class="name"></div></div></div></div>
                            <div class="loot-item" id="$lootid"><div class="loot-item-icon"><img :src="require('@/assets/empty_slot.png')"></div><div class="loot-item-name"><div style="display: table; width: 215px; height: 75px;"><div class="name"></div></div></div></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-xs-4 h100">
            <div class="dbg">
                <h3>Списки</h3>
                <h4>Для форума:</h4>
                <div name="list-forum">
                    <template v-if="!generated_data">Нет данных</template><span v-for="item of generated_data">{item:{{ item.item_id }}}<br></span>
                </div>
                <hr>
                <h4>Для игры:</h4>
                <div name="list-game">
                    <template v-if="!generated_data">Нет данных</template><span v-for="item of generated_data">{{ item.item_id }}<br></span>
                </div>
                <hr>
                <h4>Команды:</h4>
                <div name="list-commands">
                    <template v-if="!generated_data">Нет данных</template><span v-for="item of generated_data">add {{ item.item_id }}<br></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            instances: null,
            bosses: null,
            ignore: true,
            generated_data: null,
            selection: {
                instance: null,
                boss: null,
            },
            tooltip: null,
            tooltip_show: false,
            tooltipX: -1000,
            tooltipY: -1000,
            statType: [
                "к мане",
                "к здоровью",
                null,
                "к ловкости",
                "к силе",
                "к интеллекту",
                "к духу",
                "к выносливости",
                null,
                null,
                null,
                null,
                "Рейтинг защиты +{0}.",
                "Рейтинг уклонения +{0}.",
                "Рейтинг парирования +{0}.",
                "Рейтинг блокирования щитом +{0}.",
                "Рейтинг меткости (оруж. ближ. боя) +{0}.",
                "Рейтинг меткости (оруж. дальн. боя) +{0}.",
                "Рейтинг меткости (заклинания) +{0}.",
                "Рейтинг крит. удара оруж. ближнего боя +{0}.",
                "Рейтинг крит. удара оруж. дальнего боя +{0}.",
                "Рейтинг критического удара (заклинания) +{0}.",
                "Рейтинг уклонения от удара оруж. ближ. боя +{0}.",
                "Рейтинг уклонения от удара оруж. дальн. боя +{0}.",
                "Рейтинг уклонения от удара (заклинания) +{0}.",
                "Рейтинг уклонения от крит. удара оруж. ближнего боя +{0}.",
                "Рейтинг уклонения от крит. удара оруж. дистанц. боя +{0}.",
                "Рейтинг уклонения от крит. удара (заклинания) +{0}.",
                "Рейтинг скорости ближнего боя +{0}.",
                "Рейтинг скорости дальнего боя +{0}.",
                "Рейтинг скорости боя (заклинания) +{0}.",
                "Рейтинг меткости +{0}.",
                "Рейтинг критического удара +{0}.",
                "Рейтинг уклонения от удара +{0}.",
                "Рейтинг уклонения от крит. удара +{0}.",
                "Рейтинг устойчивости +{0}.",
                "Рейтинг скорости +{0}.",
                "Рейтинг мастерства +{0}.",
                "Увеличивает силу атаки на {0}.",
                "Сила атаки дальнего боя +{0}.",
                "Увеличивает силу атаки на {0} в облике кошки, медведя, лютого медведя или лунного совуха.",
                "Рейтинг натиска +{0}.",
                "Увеличивает целительное действие магических заклинаний и эффектов на {0} ед.",
                "Восполнение {0} ед. маны раз в 5 секунд.",
                "Повышает рейтинг пробивания брони на {0}.",
                "Увеличивает силу заклинаний на {0}.",
                "Восполняет {0} ед. здоровья каждые 5 секунд.",
                "Увеличивает проникающую способность заклинаний на {0}.",
                "Увеличивает показатель блокирования щита на {0}.",
                "Unknown Bonus #{0} ({1})"
            ],
            inventoryType: {
                0: null,
                1: "Голова",
                2: "Шея",
                3: "Плечи",
                4: "Рубашка",
                5: "Грудь",
                6: "Пояс",
                7: "Ноги",
                8: "Ступни",
                9: "Запястья",
                10: "Кисти рук",
                11: "Палец",
                12: "Аксессуар",
                13: "Одноручное",
                14: "Левая рука",
                15: "Дальний бой",
                16: "Спина",
                17: "Двуручное",
                18: "Сумка",
                19: "Гербовая накидка",
                20: null,
                21: "Правая рука",
                22: "Левая рука",
                23: "Левая рука",
                24: "Боеприпасы",
                25: "Метательное",
                26: null,
                27: "Колчан",
                28: "Реликвия"
            },
            slots: {
                0: null,
                1: "Голова",
                2: "Шея",
                3: "Плечи",
                4: "Рубашка",
                5: "Грудь",
                6: "Пояс",
                7: "Ноги",
                8: "Ступни",
                9: "Запястья",
                10: "Перчатки",
                11: "Палец",
                12: "Аксессуар",
                13: "Одноручное",
                14: "Щит",
                15: "Дальний бой",
                16: "Спина",
                17: "Двуручное",
                18: "Сумка",
                19: "Гербовая накидка",
                20: "Грудь",
                21: "Правая рука",
                22: "Левая рука",
                23: "Левая рука",
                24: "Амуниция",
                25: "Метательное",
                26: "Дальний бой",
                27: "Колчан",
                28: "Реликвия"
            },
            subClasses: {
                0: {
                    "-3": "Временное улучшение",
                    0: "Расходуется",
                    1: "Зелье",
                    2: "Эликсир",
                    3: "Фляга",
                    4: "Свиток",
                    5: "Еда и напитки",
                    6: "Улучшение",
                    7: "Бинты",
                    8: "Разное (расходуемые)"
                },
                1: {
                    0: "Сумка",
                    1: "Сумка душ",
                    2: "Сумка травника",
                    3: "Сумка зачаровывателя",
                    4: "Сумка инженера",
                    5: "Сумка ювелира",
                    6: "Сумка шахтера",
                    7: "Сумка кожевника",
                    8: "Сумка начертателя"
                },
                2: {
                    0: "Одноручный топор",
                    1: "Двуручный топор",
                    2: "Лук",
                    3: "Огнестрельное",
                    4: "Одноручное дробящее",
                    5: "Двуручное дробящее",
                    6: "Древковое",
                    7: "Одноручный меч",
                    8: "Двуручный меч",
                    10: "Посох",
                    13: "Кистевое оружие",
                    14: "Разное",
                    15: "Кинжал",
                    16: "Метательное",
                    18: "Арбалет",
                    19: "Жезл",
                    20: "Удочка"
                },
                3: {
                    0: "Красный самоцвет",
                    1: "Синиий самоцвет",
                    2: "Желтый самоцвет",
                    3: "Фиолетовый самоцвет",
                    4: "Зеленый самоцвет",
                    5: "Оранжевый самоцвет",
                    6: "Особый самоцвет",
                    7: "Простой самоцвет",
                    8: "Радужный самоцвет"
                },
                4: {
                    "-2": "Кольцо",
                    "-3": "Амулет",
                    "-4": "Аксессуар",
                    "-5": "Левая рука",
                    "-6": "Спина",
                    "-7": "Гербовая накидка",
                    "-8": "Рубашка",
                    0: "Разное",
                    1: "Тканевые",
                    2: "Кожаные",
                    3: "Кольчужные",
                    4: "Латные",
                    6: "Щит",
                    7: "Манускрипт",
                    8: "Идол",
                    9: "Тотем",
                    10: "Печать"
                },
                6: {
                    2: "Стрелы",
                    3: "Пули"
                },
                7: {
                    1: "Деталь",
                    2: "Взрывчатка",
                    3: "Устройство",
                    4: "Ювелирное дело",
                    5: "Ткань",
                    6: "Кожа",
                    7: "Металл и камни",
                    8: "Мясо",
                    9: "Растение",
                    10: "Элементаль",
                    11: "Разное (хозяйственные товары)",
                    12: "Наложение чар",
                    13: "Материалы",
                    14: "Улучшение брони",
                    15: "Улучшение оружия"
                },
                9: {
                    0: "Книга",
                    1: "Кожевничество",
                    2: "Портняжное дело",
                    3: "Инженерное дело",
                    4: "Кузнечное дело",
                    5: "Кулинария",
                    6: "Алхимия",
                    7: "Первая помощь",
                    8: "Зачаровывание",
                    9: "Рыбная ловля",
                    10: "Ювелирное дело",
                    11: "Технология Начертания"
                },
                11: {
                    2: "Колчан",
                    3: "Подсумок"
                },
                15: {
                    "-2": "Фрагмент доспеха",
                    "-6": "Питомец",
                    "-7": "Летающий транспорт",
                    0: "Хлам",
                    1: "Реагент",
                    2: "Спутник",
                    3: "Праздник",
                    4: "Разное",
                    5: "Транспорт"
                },
                16: {
                    1: "Символ воина",
                    2: "Символ паладина",
                    3: "Символ охотника",
                    4: "Символ разбойника",
                    5: "Символ жреца",
                    6: "Символ рыцаря смерти",
                    7: "Символ шамана",
                    8: "Символ мага",
                    9: "Символ чернокнижника",
                    11: "Символ друида"
                }
            },
        }
    },
    methods: {
        itemhover: function(event, data){
            const me = this;
            me.tooltip_show = true;
            me.tooltip = data;
            const tooltip = me.$refs.tooltip;
        },
        itemoff: function(){
            const me = this;
            me.tooltip_show = false;
        },
        refresh_bosses: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance/' + me.selection.instance.id,
            }).then((response)=>{
                me.bosses = response.data;
            });
        },
        refresh_instances: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance',
            }).then((response)=>{
                me.instances = response.data;
            });
            for(const item of me.instances){
                item.title = item.name;
            }
        },
        generate: async function(){
            const me = this;
            await axios.request({
                method:'post',
                url: me.base_url + '/v1/generate',
                data: {
                    ignore: me.ignore,
                    boss_id: me.selection.boss.id,
                }
            }).then(response => {
                me.generated_data = response.data
            });
        }
    },
    async created(){
        const me = this;
        await me.refresh_instances(); 

        /* el.addEventListener('mouseover', function(event) {
          vnode.context.showTooltip(event);
        });
        el.addEventListener('mouseleave', function(event) {
          vnode.context.hideTooltip();
        }); */
    },
}

</script>

<style lang="scss">
h2 {
    text-align: center;
}
.flex {
	width: 100%;
	height:calc(100vh - 24px);
	display: -webkit-flex; 
	-webkit-flex-direction: row;
	display: flex;
	flex-direction: row;
    
	background-image: url(@/assets/background.png);
	background-size: cover;
}
.btn-warcraft {
    background-color: rgb(107,0,0);
    border: 2px outset rgb(100,100,100);
    color: rgb(218,165,32) !important;
    &[disabled] {
        background-color: rgb(110,110,110);
    }
    &:hover{
        background-color: rgb(115,0,0);
        color: rgb(228,175,42);
    }
}
.col-xs-12 {
    width: 100%;
}
.col-xs-3{
    width: 25%;
}
.h100 {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 16px solid rgba(0,0,0,0);
	border-image: url(@/assets/frame.png) 16 round;
}
.item-list {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.item-list ul {
	flex-grow: 3;
	flex-shrink: 1;
	border: 1px solid rgba(0,0,0,0.5);
	background-color: /*rgba(54,32,19,1);*/rgba(0,0,0,0.25);
	overflow-y: scroll;
	overflow-x: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 8px;
}
.item-list ul li {
	height: 36px;
	line-height: 36px;
	font-size: 16px;
	white-space: nowrap;
	padding: 0 8px;
	border-bottom: 1px solid rgba(0,0,0,0.5);
    color: white;
	text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
	cursor: pointer;

}
.item-list ul li:hover {
	background-color: rgba(0,0,0,0.2);
}
.item-list ul li:last-child {
	border-bottom: none;
}
.dbg {
	/*background-image: url(../images/background-compressed-scale-6_00x.png);
	background-size: contain;
	background-repeat: repeat-y;
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	width: 100%;*/
}
.loot-top {
	background-image: url('@/assets/loot2-compressed-scale-6_00x.png');
	background-size: contain;
	background-repeat: no-repeat;
	width: 379px;
	height: 135px;
	position: relative;
}
.loot-title {
	position: absolute;
	color: gold;
	font-size: 24px;
	top: 32px;
	left: 200px;
}
.loot-bottom {
	border: 24px solid transparent;
	border-image: url(@/assets/loot1-compressed-scale-6_00x.png) 44 74 58 68 round;
	margin-left: 23px;
	width: 350px;
	min-height: 100px;
	position: relative;
}
.loot-bg{
	background-image: url(@/assets/background.png);
	background-size: cover;
	background-repeat: repeat-y;
}
.loot-item {
	display: -webkit-flex; 
	display: flex;
}
.loot-item-icon {
	width: 75px;
	height: 75px;
	min-width: 75px;
}
.loot-item-icon img{
	width: 100%;
	height: 100%;
}
.loot-item-name {
	flex-grow: 3;
	flex-shrink: 3;
	margin-left: 4px;
	margin-right: 4px;
	font-size: 16px;

	height: 75px;

	border: 2px inset rgba(100,100,100,0.7);
	border-radius: 4px;
	background-color: rgba(0,0,0,0.2);
}
.loot-bg ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
div.name{
	position: absolute;
	height: 75px;
	width: 215px;
	display: table;
}
div.name-in {
	display: table-cell;
	padding: 4px;
	width: 215px;
	height: 75px;
	vertical-align: middle;
	text-overflow: ellipsis; 
	overflow: hidden; 
	white-space: pre-wrap;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	line-clamp: 3; 
}
input[type=checkbox] {
	position: absolute;
  z-index: -1;
  opacity: 0;
}
input[type=checkbox] + label[name=fullrandom]{
	display: inline-flex;
	align-items: center;
	user-select: none;
}

input[type=checkbox] + label[name=fullrandom]::before {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	flex-shrink: 0;
	flex-grow: 0;
	width: 48px;
	height: 48px;
	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("@/assets/UI-CheckBox.png");
}
input[type=checkbox]:checked + label[name=fullrandom]::before {
	background-image: url("@/assets/UI-CheckBox-Check.png");
}

/* Item quality colors */
.q  { color: #ffd100 }
.q0, .q0 a { color: #9d9d9d }
.q1, .q1 a { color: #ffffff }
.q2, .q2 a, .q2:hover { color: #1eff00 }
.q3, .q3 a { color: #0070dd }
.q4, .q4 a { color: #a335ee }
.q5, .q5 a { color: #ff8000 }
.q6, .q6 a { color: #e5cc80 }
.q7, .q7 a { color: #e5cc80 }
.q8, .q8 a { color: #ffff98 }
.q9, .q9 a { color: #71D5FF }
.q10, .q10 a { color: #ffd100 }
.q11, .q11 a { color: #ff4040 }

.c1,.c1 a { color: #c69b6d !important }
.c2,.c2 a { color: #f48cba !important }
.c3,.c3 a { color: #aad372 !important }
.c4,.c4 a { color: #fff468 !important; text-shadow: #000 1px 1px; }
.c5,.c5 a { color: #fff !important; text-shadow: 1px 1px 1px black; }
.c6,.c6 a { color: #c41e3b !important }
.c7,.c7 a { color: #2359ff !important }
.c8,.c8 a { color: #68ccef !important }
.c9,.c9 a { color: #9382c9 !important }
.c10,.c10 a { color: #008467 !important }
.c11,.c11 a { color: #ff7c0a !important }
.c12,.c12 a { color: #a330c9 !important }

.s-wow-tooltip[data-type="npc"],
.s-wow-tooltip[data-type="quest"] {
    color: #ffd100;
}

.s-wow-tooltip[data-type="achievement"],
.s-wow-tooltip[data-type="npc"],
.s-wow-tooltip[data-type="quest"] {
    text-shadow: #000 1px 1px;
}

/* Sockets */
.socket-slot {
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 23px;
}
.socket-meta   { background-image: url(@/assets/armory/socket_meta.gif) }
.socket-red    { background-image: url(@/assets/armory/socket_red.gif) }
.socket-yellow { background-image: url(@/assets/armory/socket_yellow.gif) }
.socket-blue   { background-image: url(@/assets/armory/socket_blue.gif) }
.socket-prismatic { background-image: url(@/assets/armory/socket-prismatic.gif) }
.socket-hydraulic { background-image: url(@/assets/armory/socket-hydraulic.gif) }
.socket-cogwheel { background-image: url(@/assets/armory/socket-cogwheel.gif) }
/* Tooltip */
#s-wow-tooltip.active {
    padding: 5px 7px;
    background-color:#070c21;
    background-color: rgba(7,12,33,0.9);
    border-radius: 3px;
    border:1px solid #777777;
    border-top:1px solid #cfcfcf;
    -webkit-box-shadow: 0px 0px 3px #000;
    -moz-box-shadow: 0px 0px 3px #000;
    box-shadow: 0px 0px 3px #000;
    color: #fff;
    font-size: 12px;
    line-height:1.5;
    /*display: none;*/
    /*position: absolute;
    z-index: 15000;*/
}

@media (max-width: 400px)
{
    .s-wow-tooltip-body
    {
        width: 265px;
    }
}

.s-wow-tooltip-body
{
    width: 330px;
}

.s-wow-tooltip-body.s-wow-tooltip-body-quest
{
    width: unset;
    min-width: 330px;
}

.s-wow-tooltip-body .icon {
    width: 45px;
    position: absolute;
    left: -50px;
    top: -1px;
}

.s-wow-tooltip .icon {
    width: 18px;
    vertical-align: middle;
}

.s-wow-tooltip-spell .s-wow-tooltip--rank {
    float: right;
    color: #d3d3d3;
    font-size: 15px;
}
.s-wow-tooltip--title {
    font-size: 16px;
    float: left;
    margin-bottom: 5px;
}
.s-wow-tooltip-body-quest .w-100 {
    width:100%;
}

.s-wow-tooltip--points {
    font-size: 80%;
}

.item-set-piece {
    padding-left: 10px;
}

.s-wow-tooltip.q1, .s-wow-tooltip.q1 a {
    text-shadow: 1px 1px 0.05rem black;
}

.s-wow-tooltip--title .s-wow-tooltip--points {
    float: right;
    margin-top: 2px;
}

.s-wow-tooltip-guild .s-wow-tooltip--points {
    float: right;
    margin-top: 2px;
    font-size: 110%;
}

.s-wow-tooltip-clear {
    clear: both;
}

.list-unstyled
{
    list-style-type: none;
    margin-block-start: 0;
    margin-inline-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.s-wow-tooltip-body ul {
    list-style-type: none;
    padding-left: 5px;
}

.s-wow-tooltip-body.s-wow-tooltip-body-quest ul {
    list-style-type: none;
    padding-left: 0px;
}

.s-wow-tooltip-body ul > li:before {
    content: "-";
    text-indent: -5px;
}
.moneygold {
    padding-left: 14px;
    background: url(@/assets/icons/money-gold.gif) left center no-repeat;
}

.moneysilver {
    padding-left: 14px;
    background: url(@/assets/icons/money-silver.gif) left center no-repeat;
}

.moneycopper {
    padding-left: 14px;
    background: url(@/assets/icons/money-copper.gif) left center no-repeat;
}

.icon-horde {
    padding-left: 18px;
    background: url(@/assets/icons/horde.gif) left center no-repeat;
}

.icon-alliance {
    padding-left: 12px;
    background: url(@/assets/icons/alliance.gif) left center no-repeat;
}

.icon-renegade {
    padding-left: 14px;
    background: url(@/assets/icons/renegade.gif) left center no-repeat;
}

.col-xs-4 {
  width: 33.33333333333333%;
}
</style>
