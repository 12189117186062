<template>
    <div style="padding: 0px;" class="flex">
        <div class="col-xs-3 h100">
            <h2>Подземелья</h2>
            <div class="item-list">
                <div v-if="!instances || (instances && !instances.length)">Подземелий нету</div>
                <ul v-if="instances && instances.length">
                    <li
                        v-for="item of instances"
                        :style="{'background-color': (models.instance.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.instance.select = item; 
                        models.boss.select = null; 
                        models.itemset.select = null; 
                        models.item.select = null; 
                        itemsets = null;
                        items = null;
                        refresh_bosses();"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить подземелье">
                            <v-card-text>
                                Укажите название добавляемого подземелья
                                <v-text-field v-model="models.instance.create.name" label="Название подземелья"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.instance.select" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать подземелье">
                            <v-card-text>
                                Укажите новое название подземелья
                                <v-text-field v-model="models.instance.select.name" label="Название подземелья"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_update(), isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.instance.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить подземелье">
                            <v-card-text>
                                Вы уверены что хотите удалить подземелье "{{models.instance.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_delete(), isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Боссы</h2>
            <div class="item-list">
                <div v-if="!bosses || (bosses && !bosses.length)">Боссов нету</div>
                <ul v-if="bosses && bosses.length">
                    <li
                        v-for="item of bosses"
                        :style="{'background-color': (models.boss.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.boss.select = item; models.itemset.select = null; models.item.select = null; refresh_itemsets(); items = null;"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.instance.select" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить босса">
                            <v-card-text>
                                Укажите имя добавляемого в "{{ models.instance.select.name }}" босса
                                <v-text-field v-model="models.boss.create.name" label="Имя босса"></v-text-field>
                                <v-text-field v-model="models.boss.create.priority" type="number" step="0.01" label="Приоритет"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.boss.select" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать босса">
                            <v-card-text>
                                Укажите новое имя босса
                                <v-text-field v-model="models.boss.select.name" label="Имя босса"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_update(), isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <!-- <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.boss.select" v-bind="activatorProps" text="Копировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Копировать босса">
                            <v-card-text>
                                Укажите имя добавляемого в "{{ models.instance.select.name }}" босса на основе босса "{{ models.boss.select.name }}"<br>
                                Новому боссу будут скопированы его группы предметов и содержащиеся в них предметы.
                                <v-text-field v-model="models.boss.create.name" label="Имя босса"></v-text-field>
                                <v-text-field v-model="models.boss.create.priority" type="number" step="0.01" label="Приоритет"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog> -->
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.boss.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить босса">
                            <v-card-text>
                                Вы уверены что хотите удалить босса "{{models.boss.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="null, isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Группы предметов</h2>
            <div class="item-list">
                <div v-if="!itemsets || (itemsets && !itemsets.length)">Групп предметов нет</div>
                <ul v-if="itemsets && itemsets.length">
                    <li
                        v-for="item of itemsets"
                        :style="{'background-color': (models.itemset.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.itemset.select = item; models.item.select = null; refresh_items()"
                    >
                        {{ item.name }} - {{ item.chance * 100 }}%
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.boss.select" class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить группу предметов">
                            <v-card-text>
                                Укажите название добавляемой группы предметов
                                <v-text-field v-model="models.itemset.create.name" label="Название группы предметов"></v-text-field>
                                <v-text-field v-model="models.itemset.create.chance" label="Шанс в % (1-100)" min="0" max="100" step="1" type="number"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.itemset.select" class="btn-warcraft" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать группу предметов">
                            <v-card-text>
                                Укажите данные изменяемой группы предметов
                                <v-text-field v-model="models.itemset.select.name" label="Название группы предметов"></v-text-field>
                                <v-text-field v-model="models.itemset.select.chance" label="Шанс в % (1-100)" min="0" max="100" step="1" type="number"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_update(); isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.itemset.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить группу предметов">
                            <v-card-text>
                                Вы уверены что хотите удалить группу предметов "{{models.itemset.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_delete(); isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Предметы</h2>
            <div class="item-list">
                <div v-if="!items || (items && !items.length)">Предметов нету</div>
                <ul v-if="items && items.length">
                    <li
                        v-for="item of items"
                        :style="{'background-color': (models.item.select != item) ? (item.blacklisted ? 'rgba(255,255,0,0.3)': 'inherit') : 'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        @click="models.item.select = item; null" ref="armoryItem"
                        @auxclick="blacklist($event, item)"
                    >
                        <table>
                            <tr>
                                <td><v-img :src="item.json_data.icon" width="24px" /></td>
                                <td :class="`q${item.json_data.quality}`">{{ item.json_data.name }} <span v-if="item.json_data.is_heroic" style="color: green; font-size: 12px;">Героический</span></td>
                            </tr>
                        </table>
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.itemset.select" class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить предмет">
                            <v-card-text>
                                Укажите ID добавляемого предмета или предметов через запятую
                                <v-text-field v-model="models.item.create.item_id" label="ID предмета"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="item_create(); isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.item.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить предмет">
                            <v-card-text>
                                Вы уверены что хотите удалить предмет "{{models.item.select.json_data.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="item_delete(), isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
    </div>
    <!-- Тултип -->
    <div v-show="showTooltip" ref="tooltip" class="tooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
        test
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return {
            showTooltip: false,
            tooltipData: null,
            tooltipX: 0,
            tooltipY: 0,
            instances: null,
            bosses: null,
            itemsets: null,
            items: null,
            models: {
                instance: {
                    select: null,
                    create: {
                        name: null,
                    }
                },
                boss: {
                    select: null,
                    create: {
                        name: null,
                        priority: 1.0
                    }
                },
                itemset: {
                    select: null,
                    create: {
                        name: null,
                        chance: 100
                    }
                },
                item: {
                    select: null,
                    create: {
                        item_id: null,
                    }
                }
            }
        }
    },
    methods: {
        refresh_instances: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance',
            }).then((response)=>{
                me.instances = response.data;
            });
        },
        refresh_bosses: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance/' + me.models.instance.select.id,
            }).then((response)=>{
                me.bosses = response.data;
            });
        },
        refresh_itemsets: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/boss/' + me.models.boss.select.id,
            }).then((response)=>{
                me.itemsets = response.data;
            });
        },
        refresh_items: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/itemset/' + me.models.itemset.select.id,
            }).then((response)=>{
                me.items = response.data;
            });
        },
        instance_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/instance/create',
                data: {
                    name: me.models.instance.create.name,
                },
            });
            me.models.instance.select = null;
            me.models.instance.create.name = null;
            await me.refresh_instances(); 
        },
        boss_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/boss/create',
                data: {
                    instance_id: me.models.instance.select.id,
                    name: me.models.boss.create.name,
                    priority: me.models.boss.create.priority,
                },
            });
            me.models.boss.select = null;
            me.models.boss.create.name = null;
            me.models.boss.create.priority = 1.0;
            await me.refresh_bosses();
        },
        itemset_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/itemset/create',
                data: {
                    boss_id: me.models.boss.select.id,
                    name: me.models.itemset.create.name,
                    chance: me.models.itemset.create.chance,
                },
            });
            me.models.itemset.select = null;
            me.models.itemset.create.name = null;
            me.models.itemset.create.chance = 100;
            await me.refresh_itemsets();
        },
        item_create: async function(){
            const me = this;
            var items = me.models.item.create.item_id.split(',');
            var noCacheItems = [];
            var cachedItems = [];

            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/cache',
                data: {
                    items: items
                }
            }).then((response) => {
                noCacheItems = response.data;
            });

            cachedItems = items.diff(noCacheItems);

            if (noCacheItems.length) {
                for(var item of noCacheItems){ // 41592,45473,28295,28298
                    var result = null;

                    setTimeout(await axios.request({
                        method: 'get',
                        url: 'https://sirus.su/api/base/9/tooltip/item/'+item+'/',
                    }).then((response) => {
                        result = {
                            item_id: item,
                            json_data: response.data.item
                        };
                    }), 500);

                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/item/create',
                        data: {
                            itemset_id: me.models.itemset.select.id,
                            item_id: item,
                        }
                    });

                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/cache/create',
                        data: result
                    });
                    
                }
                
            }
            if (cachedItems.length) {
                for(var item of cachedItems){
                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/item/create',
                        data: {
                            itemset_id: me.models.itemset.select.id,
                            item_id: item,
                        }
                    });
                }
            }
            me.models.item.select = null;
            me.models.item.create.item_id = null;
            await me.refresh_items();
        },
        instance_update: async function(){
            const me = this;
            
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/instance/update',
                data: {
                    id: me.models.instance.select.id,
                    name: me.models.instance.select.name,
                },
            });
            me.models.instance.select = null;
            me.bosses = null;
            me.itemsets = null;
            me.items = null;
            me.models.instance.create.name = null;
            await me.refresh_instances(); 
        },
        boss_update: async function(){
            const me = this;
            
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/boss/update',
                data: {
                    id: me.models.boss.select.id,
                    name: me.models.boss.select.name,
                    priority: me.models.boss.select.priority,
                },
            });
            me.models.boss.select = null;
            me.itemsets = null;
            me.items = null;
            me.models.boss.create.name = null;
            await me.refresh_bosses(); 
        },
        itemset_update: async function(){
            const me = this;
            //console.log(me.models.itemset.select);
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/itemset/update',
                data: {
                    id: me.models.itemset.select.id,
                    boss_id: me.models.itemset.select.boss_id,
                    name: me.models.itemset.select.name,
                    chance: me.models.itemset.select.chance,
                },
            });
            me.models.itemset.select = null;
            me.items = null;
            me.models.itemset.create.name = null;
            await me.refresh_itemsets(); 
        },
        blacklist: async function(event, item){
            event.preventDefault();
            const me = this;
            
            console.log(item);
            item.blacklisted = !item.blacklisted;
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/item/update',
                data: {
                    id: item.base_id,
                    blacklisted: item.blacklisted
                },
            });
            await me.refresh_items();
            me.models.item.select = item;
        },
        instance_delete: async function(){
            const me = this,
            id = me.models.instance.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/instance/destroy/' + id,
            });
            await me.refresh_instances(); 
            me.bosses = null;
            me.itemsets = null;
            me.items = null;
            me.models.instance.select = null;
            me.models.boss.select = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        boss_delete: async function(){
            const me = this,
            id = me.models.boss.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/boss/destroy/' + id,
            });
            await me.refresh_bosses(); 
            me.itemsets = null;
            me.items = null;
            me.models.boss.select = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        itemset_delete: async function(){
            const me = this,
            id = me.models.itemset.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/itemset/destroy/' + id,
            });
            await me.refresh_itemsets(); 
            me.items = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        item_delete: async function(){
            const me = this,
            id = me.models.item.select.base_id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/item/destroy/' + id,
            });
            await me.refresh_items(); 
            me.models.item.select = null;
        },
        showTooltip(event, tooltipData) {
            console.log(event, tooltipData)
            return;
            this.tooltipData = tooltipData;
            this.tooltipX = event.pageX + 10; // Смещение тултипа относительно курсора
            this.tooltipY = event.pageY + 10; // Смещение тултипа относительно курсора
            this.showTooltip = true;
        },
        hideTooltip() {
            this.showTooltip = false;
        },
    },
    async created(){
        const me = this;
        await me.refresh_instances(); 

        /* el.addEventListener('mouseover', function(event) {
          vnode.context.showTooltip(event);
        });
        el.addEventListener('mouseleave', function(event) {
          vnode.context.hideTooltip();
        }); */
    },
    mounted(){
        /* const me = this,
        item = me.$refs.item;
        tooltip.addEventListener('mouseover', function(event) {
          vnode.context.showTooltip(event);
        });
        tooltip.addEventListener('mouseleave', function(event) {
          vnode.context.hideTooltip();
        }); */
    }
}
document.addEventListener('contextmenu', event => event.preventDefault());
</script>

<style lang="scss">
h2 {
    text-align: center;
}
.flex {
	width: 100%;
	height:calc(100vh - 24px);
	display: -webkit-flex; 
	-webkit-flex-direction: row;
	display: flex;
	flex-direction: row;
    
	background-image: url(@/assets/background.png);
	background-size: cover;
}
.btn-warcraft {
    background-color: rgb(107,0,0);
    border: 2px outset rgb(100,100,100);
    color: rgb(218,165,32) !important;
    &[disabled] {
        background-color: rgb(110,110,110);
    }
    &:hover{
        background-color: rgb(115,0,0);
        color: rgb(228,175,42);
    }
}
.col-xs-3{
    width: 25%;
}
.h100 {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 16px solid rgba(0,0,0,0);
	border-image: url(@/assets/frame.png) 16 round;
}
.item-list {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.item-list ul {
	flex-grow: 3;
	flex-shrink: 1;
	border: 1px solid rgba(0,0,0,0.5);
	background-color: /*rgba(54,32,19,1);*/rgba(0,0,0,0.25);
	overflow-y: scroll;
	overflow-x: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 8px;
}
.item-list ul li {
	height: 36px;
	line-height: 36px;
	font-size: 16px;
	white-space: nowrap;
	padding: 0 8px;
	border-bottom: 1px solid rgba(0,0,0,0.5);
    color: white;
	text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
	cursor: pointer;

}
.item-list ul li:hover {
	background-color: rgba(0,0,0,0.2);
}
.item-list ul li:last-child {
	border-bottom: none;
}
</style>
