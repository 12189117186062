<template>
  <Auth v-if="authorized == 0"/>
  <div v-if="authorized == 1">
    <v-system-bar>
        Авторизован пользователь: {{ getUser && getUser.name }} 
        <router-link class="logout-btn" to="/">Генератор</router-link>
        <router-link class="logout-btn" to="/manager">Менеджер</router-link>
        <router-link class="logout-btn" to="/import">Импорт</router-link>
        <v-spacer></v-spacer>
        <span class="logout-btn" @click="logout">Logout</span>
    </v-system-bar>
    <router-view/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex'
import { auth } from '@/services/authorization.js'

// Components
import Auth from '../components/Auth.vue';
import axios from 'axios';

export default defineComponent({
  name: 'HomeView',
  data: () => ({
    authorized: -1,
  }),
  components: {
    Auth,
  },
  computed: {
      ...mapGetters([
          'getUser'
      ]),
  },
  methods: {
    ...mapMutations(['setUser']),
    /* incrementCounter(amount) {
      this.increment({ amount }); // Вызываем мутацию increment с аргументами
    } */
    
    logout: async function(){
      const me = this;
      //var result = null;

      await axios.request({
        method: 'post',
        url: me.base_url + '/logout',
      });
      location.reload();
    }
  },
  async created(){
    // this.getCookie('laravel_session')
    const check = await auth.check();
    
    this.authorized = check.success ? 1 : 0;
    if (check.success) {
      this.setUser(check.data);
    }
  },
});
</script>

<style lang="scss">

.logout-btn {
    display: block;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 0 8px;
    border-radius: 4px;
    color: rgba(0,0,0,0.8);
    margin: 0 4px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        background-color: rgba(0,0,0,1);
        color: rgba(255,255,255,0.8);
    }
}
@font-face {
  font-family: 'FrizQuadrata';
  src: url('@/assets/fonts/FrizQuadrataC.otf');
}
/* width */
*::-webkit-scrollbar {
width: 0px;
}

/* Track */
*::-webkit-scrollbar-track {
background: rgba(0,0,0,0);
}

/* Handle */
*::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
background: #555;
}
* {
	outline: none;
  font-family: 'FrizQuadrata';
}
h2, h3, h4{
	text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
	font-family: 'FrizQuadrata';
  color: white;
}
body{
	overflow: hidden;
}
body {
	min-width: 1280px;
	max-height: 910px;
	color: white;
	height: 100%;
	/*background-image: url(@/assets/background.png);
	background-size: cover;*/
}
.q {
    color: #ffd100
}

.q0,.q0 a {
    color: #9d9d9d
}

.q1,.q1 a {
    color: #fff
}

.q2,.q2 a,.q2:hover {
    color: #1eff00
}

.q3,.q3 a {
    color: #0070dd
}

.q4,.q4 a {
    color: #a335ee
}

.q5,.q5 a {
    color: #ff8000
}

.q6,.q6 a,.q7,.q7 a {
    color: #e5cc80
}

.q8,.q8 a {
    color: #ffff98
}

.q9,.q9 a {
    color: #71d5ff
}

.q10,.q10 a {
    color: #ffd100
}

.q11,.q11 a {
    color: #ff4040
}
</style>
